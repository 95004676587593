// import config from '../config'

export interface ListEditorProps {

}

// login register 
// song edit

function ListEditor(props: ListEditorProps) {

  return (
    <div className="px-6 py-4 flex flex-col items-center">
      <p>ListEditor</p>
    </div>
  );

}

export default ListEditor
