import { Navigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"

interface ProtectedRouteProps {
  children: JSX.Element
}

export function ProtectedRoute(props: ProtectedRouteProps) {
  const auth = useAuth()
  if (auth === null || !auth.user) {
    // user is not authenticated
    return <Navigate to="/account" />
  }
  return props.children
}