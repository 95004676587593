import React from 'react';
import { useState } from 'react';
import './App.css';
import Header from './components/Header';
import SongList from './components/SongList';
import { SongItemProps } from './components/SongItem';
import Popup from './components/Popup';
import { PopupProperty } from './components/utility';
import config from './config';

function App() {
  const [popupChanged, setIsDisplayed] = useState<PopupProperty | undefined>();

  // document.querySelector<HTMLElement>('head>title')!.innerText = 'haha'


  let songs: SongItemProps[] = []
  songs = config.songs.map<SongItemProps>((e) => {
    const es = e.split(',')
    if (es.length > 2) {
      return {
        title: es[0],
        artist: es[1],
        language: es[2],
      }
    }
    else if (es.length > 1) {
      return {
        title: es[0],
        artist: es[1],
      }
    }
    return { title: e }
  });
  // for (var i = 0; i < 100; i++) {
  //   songs.push({
  //     title: `col ${i}`,
  //     artist: `col ${i}`,
  //     language: `col ${i}`,
  //     price: i,
  //     isSuggested: false,
  //   })
  // }

  return (
    <div className="App bg-gray-200 dark:bg-black">
      <Header />
      <SongList
        songs={songs}
        onTap={async (v) => {
          setIsDisplayed(v)
          setTimeout(() => setIsDisplayed({ isDisplayed: false, message: '' }), 5000);
        }}
      />

      {/* <Popup /> */}

      {popupChanged?.isDisplayed ? <Popup message={popupChanged.message} /> : null}

    </div>
  );
}



export default App;
