// import { useState } from 'react';
// import config from '../config'
// import { useAuth } from '../hooks/useAuth';
import React from 'react';



interface Credentials {
  userId: string
  password: string
  secret: string
}



type AccountProps = {
}
type AccountState = {
  // userId: string
  // password: string
  // secret: string
  credentials: Credentials
  isLoginFailed: boolean
}
class Account extends React.Component<AccountProps, AccountState> {
  state: AccountState = {
    credentials: {
      userId: '',
      password: '',
      secret: '',
    },
    isLoginFailed: false,
  };
  render() {
    let tip = this.state.isLoginFailed ? <p className="text-red-500 text-xs italic">login failed</p> : <></>

    return (
      <div className="h-screen bg-gray-200 w-full flex justify-center items-center">
        <div className="w-full max-w-xs">
          {tip}
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                Username
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Username"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <input
                // border-red-500
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="******************"
              />
              {/* <p className="text-red-500 text-xs italic">Please choose a password.</p> */}
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="secret">
                secret
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="secret"
                type="text"
                placeholder="secret code"
              />
            </div>
            <div className="flex items-center justify-around">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={() => {
                  let userId = (document.querySelector('#username') as HTMLInputElement).value
                  let password = (document.querySelector('#password') as HTMLInputElement).value
                  let secret = (document.querySelector('#secret') as HTMLInputElement).value
                  let d = `uid: ${userId} password: ${password} secret: ${secret}`

                  console.log('data: ', d)
                  this.login({ userId: userId, password: password, secret: secret }).then((data) => {
                    console.log('result')
                  })
                }}
              >
                Sign In
              </button>
              {/* <a className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                Forgot Password?
              </a> */}
            </div>
          </form>
          <p className="text-center text-gray-500 text-xs">
            {/* &copy;2020 Acme Corp. All rights reserved. */}
          </p>
        </div>
      </div>
    )

  }

  async login(credentials: Credentials): Promise<boolean> {
    const uri = '/api/account/login'
    // const uri = 'http://localhost:8788/api/account/login'
    const data = await fetch(uri, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })

    console.log('data:', data)

    const responseJson = await data.json()

    console.log('responseJson:', responseJson)

    return false
  }

}

export default Account
