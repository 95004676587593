import { createContext, useContext, useMemo, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useLocalStorage } from "./useLocalStorage"


interface AuthProviderProps {
  children: JSX.Element[]
}

interface AuthContextType {
  user: string | null
  login: (data: string) => Promise<void>
  logout: () => void
}

const AuthContext = createContext<AuthContextType | null>(null)

export function AuthProvider1(props: AuthProviderProps) {
  const [user, setUser] = useLocalStorage("user", null)
  const navigate = useNavigate()

  // call this function when you want to authenticate the user
  // const login = async (data: string) => {
  //   setUser(data)
  //   navigate("/profile")
  // }

  // // call this function to sign out logged in user
  // const logout = () => {
  //   setUser(null)
  //   navigate("/", { replace: true })
  // }

  const login = useCallback(async (data: string) => {
    setUser(data)
    navigate("/profile")
  }, [navigate, setUser])

  const logout = useCallback(() => {
    setUser(null)
    navigate("/", { replace: true })
  }, [navigate, setUser])

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user, login, logout]
  )

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextType | null {
  const r = useContext(AuthContext)
  return r
}
